import React from "react";
import Seo from "../components/seo";
import Default from "../components/default";
import Breadcrumbs from "../components/breadcrumbs";
import { Link } from "gatsby";

const IndexPage = () => {
  return (
    <>
      <Seo title="Privacy Policy" />
      <Default>
        <section>
          <Breadcrumbs>
            <span>Privacy Policy</span>
          </Breadcrumbs>
          <h1>Privacy Policy</h1>
          <div style={{ textAlign: "left", margin: "1em" }}>
            <h2>Do you collect my data?</h2>
            <p>Your data is collected via third party plugins such as YouTube. All data goes to these third party plugins.</p>
            <h2>What are the cookies used for?</h2>
            <p>
              Cookies are used on this website for where they are necessary for site functionality.
              <br />
              They are used for the inclusion of third party plugins, such as YouTube.
              <br />
              You are responsible for reading and understanding those third party plugins' privacy policies.
            </p>
            <h2>How do I disable cookies?</h2>
            <p>You can manage cookies in your browser Privacy settings or by clicking on the padlock icon next to the site URL.</p>
            {/* <p>
              You may opt out of personalised advertising by visiting{" "}
              <a className="link" href="https://www.google.com/settings/ads">
                Ads Settings
              </a>
            </p> */}
            <br />
            <p style={{ textAlign: "center" }}>This policy may change at any time.</p>
          </div>
        </section>
      </Default>
    </>
  );
};

export default IndexPage;
